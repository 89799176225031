import { useEffect, useState } from 'react'

export type LayoutMode = 'tablet' | 'pc'

const isServer = () => typeof window === 'undefined'
const isTablet = () => typeof window !== 'undefined' && window.innerWidth <= 960

export const useLayoutMode = () => {
  const [layoutMode, setLayoutMode] = useState<LayoutMode>('pc')
  useEffect(() => {
    if (isServer()) {
      return
    }

    const updateLayoutMode = () => {
      setLayoutMode(isTablet() ? 'tablet' : 'pc')
    }

    updateLayoutMode()

    window.addEventListener('resize', updateLayoutMode)
    return () => {
      window.removeEventListener('resize', updateLayoutMode)
    }
  }, [])
  return layoutMode
}
