import { FC } from 'react'
import { useClientSetting } from 'src/clientSetting/Provider'
import { getServerSidePropsMiddlewareRequireClientSettings } from 'src/clientSetting/serverSideProps'
import { clientSettings } from 'src/clientSetting/settings'
import { ConnectParamsQuery } from 'src/connect/params'
import {
  getServerSidePropsMiddlewareAddConnectParamsToProps,
  ServerSidePropsWithConnectParams,
} from 'src/connect/serverSideProps'
import { useConnectParams } from 'src/connect/useParams'
import { useApolloErrorToast } from 'src/feedback/useApolloErrorToast'
import { useToast } from 'src/feedback/useToast'
import { useHandleLoginNavigation } from 'src/loginNavigation/useHandleLoginNavigation'
import { useLoginMutation } from './index.gen'
import { IndexCommon } from './IndexCommon'
import { IndexLatour } from './IndexLatour'
import { IndexMomo } from './IndexMomo'

const IndexPage: FC<{}> = () => {
  const toast = useToast()
  const apolloErrorToast = useApolloErrorToast()
  const handleLoginNavigation = useHandleLoginNavigation()

  const [login, loginResult] = useLoginMutation({
    onCompleted(data) {
      if (data.login.__typename === 'LoginNavigationRequireSMSRecovery') {
        toast({
          title: 'SMS送信用の携帯電話番号が利用停止中のため、ログインできません。',
          status: 'error',
        })
      }
      handleLoginNavigation(data.login)
    },
    onError: apolloErrorToast,
  })

  const { params } = useConnectParams()

  const clientId = useClientSetting() || clientSettings.default

  return (
    <>
      {clientId === clientSettings.momo ? (
        <IndexMomo
          onSubmitEmailLogin={values => {
            login({
              variables: {
                input: {
                  email: values.email,
                  password: values.password,
                  loginRequest: {
                    cookieLogin: params.cookieLogin,
                  },
                },
              },
            })
          }}
          submitting={loginResult.loading}
        />
      ) : clientId === clientSettings['curon-clinic-web'] ? (
        <IndexLatour
          onSubmitEmailLogin={values => {
            login({
              variables: {
                input: {
                  email: values.email,
                  password: values.password,
                  loginRequest: {
                    cookieLogin: params.cookieLogin,
                  },
                },
              },
            })
          }}
          submitting={loginResult.loading}
        />
      ) : clientId === clientSettings['id-clinic'] ? (
        <IndexCommon
          onSubmitEmailLogin={values => {
            login({
              variables: {
                input: {
                  email: values.email,
                  password: values.password,
                  loginRequest: {
                    cookieLogin: params.cookieLogin,
                  },
                },
              },
            })
          }}
          submitting={loginResult.loading}
        />
      ) : null}
    </>
  )
}

const Page: FC<ServerSidePropsWithConnectParams> = ({ connectParams }) => {
  return <IndexPage />
}

export type Query = ConnectParamsQuery

export const getServerSideProps = process.env.NEXT_PUBLIC_FALLBACK_TO_DEFAULT_CLIENT
  ? getServerSidePropsMiddlewareAddConnectParamsToProps()
  : getServerSidePropsMiddlewareRequireClientSettings(
      getServerSidePropsMiddlewareAddConnectParamsToProps(),
    )
export default Page
