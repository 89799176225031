import {
  Box,
  Button,
  Center,
  Checkbox,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Text,
} from '@chakra-ui/react'
import Image from 'next/image'
import NextLink from 'next/link'
import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useConnectParams } from 'src/connect/useParams'
import { reactHookFormDefaultOptions } from 'src/form/reactHookFormDefaultOptions'
import {
  compose,
  validateEmailPattern,
  validateMaxLength,
  validateRequired,
} from 'src/form/validations'
import { PagePadding } from 'src/layout/PagePadding'
import { PageRoot } from 'src/layout/PageRoot'
import { Footer } from 'src/navigation/Footer'
import { JapanOnlyNote } from 'src/navigation/Header/JapanOnlyNote'
import { pagesPath } from 'src/routes/$path'
import { CLINIC_TERMS, EXTERNAL_TRANSMISSION, PRIVACY_POLICY } from 'src/urls'
import { useLayoutMode } from 'src/userSettingNavigation/useLayoutMode'
import LatourPcTopArt from './latour_pc_top_art.svg'

type FormValues = {
  email: string
  password: string
}

export const IndexLatour: FC<{
  onSubmitEmailLogin: (values: FormValues) => void
  submitting: boolean
}> = ({ onSubmitEmailLogin, submitting, ...props }) => {
  const { getUrlQuery } = useConnectParams()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    ...reactHookFormDefaultOptions,
  })

  const [passwordVisible, setPasswordVisible] = useState<boolean>(false)

  const layoutMode = useLayoutMode()

  return (
    <>
      {layoutMode === 'tablet' ? (
        <PageRoot bg="#FFFFFF">
          <JapanOnlyNote
            backgroundColor="green.50"
            borderRadius="1px"
            borderBottom="1px solid"
            borderBottomColor="#B1DEC7"
            textColor="#2B323B"
          />
          <Container maxWidth="100%" p={0}>
            <Image
              src="/top/latour_responsive_top.png"
              width={768}
              height={200}
              alt="ログイン画面 - タブレット画面"
              layout="responsive"
            />

            <Center>
              <Box
                backgroundColor="#FFFFFF"
                width={layoutMode === 'tablet' ? '70%' : '100%'}
                py={6}
                px={12}
              >
                <form onSubmit={handleSubmit(v => onSubmitEmailLogin(v))}>
                  <Box mt="64px">
                    <FormControl id="email" isInvalid={!!errors.email}>
                      <FormLabel fontWeight="bold" color="gray.900">
                        メールアドレス
                      </FormLabel>
                      <Input
                        type="text"
                        size="lg"
                        inputMode="email"
                        placeholder="メールアドレスを入力"
                        {...register('email', {
                          ...compose(
                            validateRequired,
                            validateEmailPattern,
                            validateMaxLength(76),
                          )('メールアドレス'),
                        })}
                      />
                      <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl id="password" mt={6} isInvalid={!!errors.password}>
                      <FormLabel fontWeight="bold" color="gray.900">
                        パスワード
                      </FormLabel>
                      <Input
                        type={passwordVisible ? 'text' : 'password'}
                        size="lg"
                        placeholder="パスワードを入力"
                        {...register('password', {
                          ...compose(validateRequired)('パスワード'),
                        })}
                      />
                      <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
                    </FormControl>
                    <Box mt={4}>
                      <Checkbox
                        checked={passwordVisible}
                        onChange={e => setPasswordVisible(e.target.checked)}
                      >
                        パスワードを表示する
                      </Checkbox>
                    </Box>
                  </Box>
                  <Box mt={8}>
                    <Text color="gray.600">
                      <Link
                        href={CLINIC_TERMS}
                        isExternal
                        color="green.600"
                        textDecoration="underline"
                        mx={1}
                      >
                        利用規約
                      </Link>
                      および
                      <Link
                        href={PRIVACY_POLICY}
                        isExternal
                        color="green.600"
                        textDecoration="underline"
                        mx={1}
                      >
                        プライバシーポリシー
                      </Link>
                      ・
                      <Link
                        href={EXTERNAL_TRANSMISSION}
                        isExternal
                        color="green.600"
                        textDecoration="underline"
                        mx={1}
                      >
                        外部送信ポリシー
                      </Link>
                      に同意される方のみ、ログインください。
                    </Text>
                  </Box>
                  <Box mt={12}>
                    <Button
                      width="100%"
                      size="lg"
                      fontWeight="bold"
                      type="submit"
                      isLoading={submitting}
                    >
                      ログインする
                    </Button>
                  </Box>
                  <Box mt={6} textAlign="center">
                    <NextLink href={pagesPath.recovery.email.$url(getUrlQuery())} legacyBehavior>
                      <Link color="#2BA168" textDecoration="underline">
                        パスワードがわからない方はこちら
                      </Link>
                    </NextLink>
                  </Box>
                  <Box mt={12} textAlign="center">
                    <Link
                      href="https://id.curon.co/whitepaper.pdf"
                      color="green.600"
                      textDecoration="underline"
                      target="_blank"
                    >
                      セキュリティホワイトペーパー
                    </Link>
                  </Box>
                </form>
              </Box>
            </Center>
          </Container>
          <PagePadding />
          <Footer bg="green.50" />
        </PageRoot>
      ) : (
        <PageRoot bg="green.50">
          <JapanOnlyNote
            backgroundColor="green.50"
            borderRadius="1px"
            borderBottom="1px solid"
            borderBottomColor="#B1DEC7"
            textColor="#2B323B"
          />
          <Container
            maxWidth={800}
            p={0}
            mt="96px"
            sx={{ boxShadow: '0px 8px 20px 0px #00000014' }}
          >
            <Flex>
              <Box width="240px" height="534px" sx={{ display: 'flex' }}>
                <LatourPcTopArt style={{ width: '100%', height: '100%' }} />
              </Box>
              <Box backgroundColor="#FFFFFF" width="560px" height="534px" py="48px" px="80px">
                <form onSubmit={handleSubmit(v => onSubmitEmailLogin(v))}>
                  <Box>
                    <FormControl id="email" isInvalid={!!errors.email}>
                      <FormLabel fontWeight="bold" color="gray.900">
                        メールアドレス
                      </FormLabel>
                      <Input
                        type="text"
                        size="lg"
                        inputMode="email"
                        placeholder="メールアドレスを入力"
                        {...register('email', {
                          ...compose(
                            validateRequired,
                            validateEmailPattern,
                            validateMaxLength(76),
                          )('メールアドレス'),
                        })}
                      />
                      <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl id="password" mt={6} isInvalid={!!errors.password}>
                      <FormLabel fontWeight="bold" color="gray.900">
                        パスワード
                      </FormLabel>
                      <Input
                        type={passwordVisible ? 'text' : 'password'}
                        size="lg"
                        placeholder="パスワードを入力"
                        {...register('password', {
                          ...compose(validateRequired)('パスワード'),
                        })}
                      />
                      <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
                    </FormControl>
                    <Box mt={4}>
                      <Checkbox
                        checked={passwordVisible}
                        onChange={e => setPasswordVisible(e.target.checked)}
                      >
                        パスワードを表示する
                      </Checkbox>
                    </Box>
                  </Box>
                  <Box mt={8}>
                    <Text color="gray.600">
                      <Link
                        href={CLINIC_TERMS}
                        isExternal
                        color="green.600"
                        textDecoration="underline"
                        mx={1}
                      >
                        利用規約
                      </Link>
                      および
                      <Link
                        href={PRIVACY_POLICY}
                        isExternal
                        color="green.600"
                        textDecoration="underline"
                        mx={1}
                      >
                        プライバシーポリシー
                      </Link>
                      ・
                      <Link
                        href={EXTERNAL_TRANSMISSION}
                        isExternal
                        color="green.600"
                        textDecoration="underline"
                        mx={1}
                      >
                        外部送信ポリシー
                      </Link>
                      に同意される方のみ、ログインください。
                    </Text>
                  </Box>
                  <Box mt={8}>
                    <Button
                      width="280px"
                      size="lg"
                      fontWeight="bold"
                      type="submit"
                      isLoading={submitting}
                    >
                      ログインする
                    </Button>
                  </Box>
                  <Box mt={6} textAlign="left">
                    <NextLink href={pagesPath.recovery.email.$url(getUrlQuery())} legacyBehavior>
                      <Link color="#2BA168" textDecoration="underline">
                        パスワードがわからない方はこちら
                      </Link>
                    </NextLink>
                  </Box>
                </form>
              </Box>
            </Flex>
          </Container>
          <Container mt={12}>
            <Center>
              <Link
                href="https://id.curon.co/whitepaper.pdf"
                color="green.600"
                textDecoration="underline"
                isExternal
              >
                セキュリティホワイトペーパー
              </Link>
            </Center>
          </Container>
          <PagePadding />
          <Footer bg="green.50" />
        </PageRoot>
      )}
    </>
  )
}
