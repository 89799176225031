import { Box, Center, Link } from '@chakra-ui/react'
import { FC } from 'react'

type JapanOnlyNoteProps = {
  backgroundColor: string
  borderRadius: string
  borderBottom: string
  borderBottomColor: string
  textColor: string
}

export const JapanOnlyNote: FC<JapanOnlyNoteProps> = ({
  backgroundColor,
  borderRadius,
  borderBottom,
  borderBottomColor,
  textColor,
  ...props
}) => {
  return (
    <Box
      p={4}
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      borderBottom={borderBottom}
      borderBottomColor={borderBottomColor}
    >
      <Center>
        <Box fontSize="sm" color={textColor}>
          クロンは日本国内でのご利用を前提としており、海外ではご利用いただけません。
          <Link
            color="green.400"
            href="https://micin.my.site.com/FAQforDoctors/s/article/0132"
            target="_blank"
          >
            詳細はこちら
          </Link>
        </Box>
      </Center>
    </Box>
  )
}
