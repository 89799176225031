import { Box, ChakraProps } from '@chakra-ui/react'
import { FC, ReactNode } from 'react'

export const PageRoot: FC<{ children: ReactNode } & ChakraProps> = ({ children, ...props }) => {
  return (
    <Box
      width="100%"
      minHeight="100vh"
      sx={{
        '@supports(-webkit-touch-callout: none)': {
          minHeight: '-webkit-fill-available',
        },
      }}
      display="flex"
      flexDirection="column"
      overflowY="auto"
      {...props}
    >
      {children}
    </Box>
  )
}
